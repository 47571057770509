<template>
  <div
    id="product-tile-box"
    class="product relative align-center w-100"
    :class="{'vertical-layout mb20' : columns === 'off' }"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true
    }"
  >
    <div class="block no-underline product-link relative brdr-10px hover-shadow-4 pt15" :class="{ 'main-page' : isMainPage }">
      <span v-if="labelsActive && isOnSale" class="badge sale">{{ $t('On Sale') }}</span>
      <span v-else-if="labelsActive && isNew" class="badge new">{{ $t('New') }}</span>
      <span v-else-if="labelsActive && isBestseller" class="badge bestseller">{{ $t('Bestseller') }}</span>
      <div class="product__icons">
        <AddToWishlist :product="product">
          <div
            class="product__icon"
            :class="{'product__icon--active': isOnWishlist }"
            :title="isOnWishlist ? $t('Remove') : $t('Add to favorite') "
          >
            <i class="h3" :class="isOnWishlist ? 'icon-favourite-fill cl-main' : 'icon-favourite-stroke cl-main'" />
          </div>
        </AddToWishlist>
      </div>
      <router-link
        :to="productLink"
        data-testid="productLink"
        class="product__link"
        :event="linkActive ? 'click' : 'none'"
        @click.native="productClick"
      >
        <div class="product-image-box relative pt15 bg-cl-main-smoke">
          <product-image
            class="product-cover__thumb"
            :image="!seen || hoverPath ? loadingThumbnailObj : thumbnailObj "
            :alt="product.name | htmlDecode"
            :calc-ratio="false"
            data-testid="productImage"
          />
        </div>
        <div class="vertical-layout__content relative">
          <p
            class="mb0 cl-black align-left pt10 product-name mx10 brdr-top-1 brdr-cl-white-smoke"
            :class="{'product-long-name': product.name.length > 35 }"
            v-if="!onlyImage"
          >
            {{ product.name | htmlDecode }}
          </p>
          <div
            class="product-price relative px10 pb15 mt10 h4"
            :class="[{ sale: labelsActive && isOnSale }, { new: labelsActive && isNew }]"
          >
            <span
              class="cl-black weight-600"
              v-if="bundleMinPrice && !bundleSpecialMinPrice && !onlyImage"
            ><span v-show="!isBundleOptionNotChangeable">od:</span> {{ bundleMinPrice | price(storeView) }}</span>
            <span
              class="price-special cl-burnt-sienna weight-600"
              v-if="product.promo_price"
            >{{ product.promo_price | price(storeView) }}</span>
            <span
              class="price-special weight-600"
              :class="isSaleDifference && omnibusPrice ? 'cl-burnt-sienna' : 'cl-black'"
              v-else-if="(bundleSpecialMinPrice || product.special_price && parseFloat(product.original_price_incl_tax) > 0) && !onlyImage"
            >{{ specialPrice | price(storeView) }}</span>
            <span
              class="price-original ml5 cl-matterhorn fs16"
              v-if="(bundleSpecialMinPrice || product.special_price && parseFloat(product.special_price) > 0) && !onlyImage && isSaleDifference && omnibusPrice"
            >{{ omnibusPrice | price(storeView) }}</span>
            <span
              class="cl-black weight-600"
              v-if="!bundleMinPrice && !product.special_price && parseFloat(product.price_incl_tax) > 0 && !onlyImage"
            >{{ product.price_incl_tax | price(storeView) }}</span>
            <add-to-cart v-if="product.type_id !== 'bundle' && product.type_id !== 'subscription'" :product="product" />
            <div v-else class="add-to-cart">
              <i class="icon-cart cl-main" />
            </div>
          </div>
          <p v-show="omnibusPrice && isSaleDifference" class="px10 align-left pb10 cl-black h6 my0">
            {{ $t(`Najniższa cena w ostatnich 30 dniach: ${omnibusPrice} zł`) }}
          </p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import rootStore from '@vue-storefront/core/store'
import { ProductTile } from '@vue-storefront/core/modules/catalog/components/ProductTile.ts'
import config from 'config'
import ProductImage from './ProductImage'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { mapState } from 'vuex'

import Vue from 'vue'
import CurrentPage from 'theme/mixins/currentPage'
import AddToCart from './blocks/Category/AddToCart.vue'
import AddToWishlist from 'theme/components/core/blocks/Wishlist/AddToWishlist'
import { IsOnWishlist } from 'src/modules/vsf-magento-wishlist/components/IsOnWishlist'
import { getBundleOptionsMinPrices } from '@vue-storefront/core/modules/catalog/helpers/bundleOptions'
import { round } from 'mathjs'
import { isSpecialPriceActive } from '@vue-storefront/core/modules/catalog/helpers/taxCalc'
import { categoryResolver } from 'theme/helpers/categoryResolverFunction'
import { provisionType } from '@vue-storefront/core/helpers'
import { getNextProduct } from 'src/modules/google-tag-manager/hooks/afterRegistration'

export default {
  mixins: [ProductTile, CurrentPage, IsOnWishlist],
  components: {
    ProductImage,
    AddToCart,
    AddToWishlist
  },
  data () {
    return {
      brand: this.product.brand,
      seen: false,
      hoverPath: false,
      loadingThumbnailObj: {
        src: '/assets/placeholder.svg',
        loading: '/assets/placeholder.svg',
        error: '/assets/placeholder.svg'
      },
      dragStartX: 0,
      dragStartY: 0,
      isTouch: typeof window !== 'undefined' && 'ontouchstart' in window
    }
  },
  props: {
    labelsActive: {
      type: Boolean,
      default: true
    },
    linkActive: {
      type: Boolean,
      default: true
    },
    onlyImage: {
      type: Boolean,
      default: false
    },
    isMainPage: {
      type: Boolean,
      default: false
    },
    position: {
      type: Number,
      default: 1
    },
    omnibusPrice: {
      type: Number,
      default: 0
    },
    list: {
      type: String,
      default: 'catalog'
    },
    columns: {
      type: [Number, String],
      default: 3
    },
    recentlyView: {
      type: Boolean,
      required: false,
      default: false
    },
    relatedProducts: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onTouchEnd (e) {
      const eventPosX =
        this.isTouch && e.changedTouches && e.changedTouches.length > 0
          ? e.changedTouches[0].clientX
          : e.clientX;
      const eventPosY =
        this.isTouch && e.changedTouches && e.changedTouches.length > 0
          ? e.changedTouches[0].clientY
          : e.clientY;
      const deltaX = this.dragStartX - eventPosX;
      const deltaY = this.dragStartY - eventPosY;

      if (Math.abs(deltaX) < 8 && Math.abs(deltaY) < 8) {
        this.$emit('product-slide-click')
      }
    },
    onTouchStart (e) {
      if (e.button === 2) {
        return;
      }
      this.dragStartX = this.isTouch ? e.touches[0].clientX : e.clientX;
      this.dragStartY = this.isTouch ? e.touches[0].clientY : e.clientY;
    },
    onImageHover (path) {
      if (path) {
        this.hoverPath = true
        this.loadingThumbnailObj.src = path
      } else {
        this.hoverPath = false
        this.loadingThumbnailObj.src = '/assets/placeholder.svg'
      }
    },
    onProductPriceUpdate (product) {
      if (product.sku === this.product.sku) {
        Object.assign(this.product, product)
      }
    },
    visibilityChanged (isVisible, entry) {
      if (
        isVisible &&
        config.products.configurableChildrenStockPrefetchDynamic &&
        config.products.filterUnavailableVariants &&
        this.product.type_id === 'configurable' &&
        this.product.configurable_children &&
        this.product.configurable_children.length > 0
      ) {
        const skus = [this.product.sku]
        for (const confChild of this.product.configurable_children) {
          const cachedItem = rootStore.state.stock.cache[confChild.id]
          if (typeof cachedItem === 'undefined' || cachedItem === null) {
            skus.push(confChild.sku)
          }
        }
        if (skus.length > 0) {
          rootStore.dispatch('stock/list', { skus: skus }) // store it in the cache
        }
      }
      if (isVisible && !this.seen) {
        if (window.dataLayer) {
          let price = this.product.special_price_incl_tax !== 0
            ? this.product.special_price_incl_tax
            : this.product.price_incl_tax
          price = Math.abs(parseFloat(price)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')
          let { deepestCategory, mainCategory, categoriesIdsFromResolver } = categoryResolver(this.product.category, this.product.main_category, this.$store)
          const inStock = this.product.stock && typeof this.product.stock.is_in_stock === 'boolean'
            ? this.product.stock.is_in_stock
              ? 'Tak'
              : 'Nie'
            : 'Nie'
          const source = this.product.stock && this.product.stock.source_code
          Vue.gtm.trackEvent({
            event: 'uaevent',
            gender: this.getLabelFor('gender') ? this.getLabelFor('gender') : '',
            eventCategory: 'Ecommerce',
            eventAction: 'Product Impressions',
            ecommerce: {
              currencyCode: 'PLN', // TODO: get currency from state
              impressions: [{
                name: this.product.name,
                metric1: this.product.regular_price,
                dimension2: provisionType(deepestCategory),
                dimension10: source || '',
                dimension11: this.isBestseller ? 'Tak' : 'Nie',
                dimension12: this.isOnSale ? 'Tak' : 'Nie',
                dimension14: this.isNew ? 'Tak' : 'Nie',
                dimension15: this.product.type_id,
                dimension16: inStock ? 'Tak' : 'Nie',
                dimension17: '',
                dimension18: this.product.author || '',
                dimension19: mainCategory,
                dimension20: deepestCategory ? deepestCategory.join(',') : '',
                childSku: this.product.sku,
                id: this.product.parentSku,
                price: this.bundleMinPrice || price,
                brand: this.getLabelFor('brand') ? this.getLabelFor('brand') : '',
                category: mainCategory,
                product_category_ids: categoriesIdsFromResolver,
                product_category_names: deepestCategory || [],
                list: this.list,
                position: this.position + 1
              }]
            }
          })
          Vue.prototype.$googleTagManager.impressions({
            impressions: [
              getNextProduct({
                item: this.product,
                list: this.list,
                list_id: this.product.parentSku,
                position: this.position
              })
            ]
          })
        }
        this.seen = true
      }
    },
    getLabelFor (type) {
      if (this.listByCode && this.listByCode[type]) {
        let items = this.listByCode[type].options && this.listByCode[type].options.filter(option => option.value === String(this.product[type]))
        return (items && items.length) && items[0].label
      }
    },
    productClick () {
      if (this.isWishlistActive && this.recentlyView) {
        this.$store.dispatch('ui/toggleWishlist')
      }
      if (window.dataLayer) {
        let price = this.product.special_price_incl_tax !== 0 ? this.product.special_price_incl_tax : this.product.price_incl_tax
        price = Math.abs(parseFloat(price)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        let { deepestCategory, mainCategory, categoriesIdsFromResolver } = categoryResolver(this.product.category, this.product.main_category, this.$store)
        const inStock = this.product.stock && typeof this.product.stock.is_in_stock === 'boolean'
          ? this.product.stock.is_in_stock
            ? 'Tak'
            : 'Nie'
          : 'Nie'
        const source = this.product.stock && this.product.stock.source_code
        window.dataLayer.push({
          event: 'uaevent',
          gender: this.getLabelFor('gender') ? this.getLabelFor('gender') : '',
          eventCategory: 'Ecommerce',
          eventAction: 'Product Click',
          ecommerce: {
            click: {
              actionField: {
                list: this.list
              },
              products: [
                {
                  name: this.product.name,
                  metric1: this.product.regular_price,
                  dimension2: provisionType(deepestCategory),
                  dimension10: source || '',
                  dimension11: this.isBestseller ? 'Tak' : 'Nie',
                  dimension12: this.isOnSale ? 'Tak' : 'Nie',
                  dimension14: this.isNew ? 'Tak' : 'Nie',
                  dimension15: this.product.type_id,
                  dimension16: inStock ? 'Tak' : 'Nie',
                  dimension17: '',
                  dimension18: this.product.author || '',
                  dimension19: mainCategory,
                  dimension20: deepestCategory ? deepestCategory.join(',') : '',
                  id: this.product.parentSku,
                  price: this.bundleMinPrice || price,
                  brand: this.getLabelFor('brand') ? this.getLabelFor('brand') : '',
                  category: mainCategory,
                  product_category_ids: categoriesIdsFromResolver,
                  product_category_names: deepestCategory || [],
                  position: this.position + 1
                }]
            }
          }
        })
        Vue.prototype.$googleTagManager.click({
          item: getNextProduct({
            item: this.product,
            list: this.list,
            list_id: this.product.parentSku,
            position: this.position
          })
        })
      }
    }
  },
  computed: {
    isSaleDifference () {
      if (!this.specialPrice || !this.omnibusPrice) return false
      const productPrice = Number(this.specialPrice.toFixed(2))
      if ((this.omnibusPrice - productPrice) <= 0) return false
      return true
    },
    specialPrice () {
      return this.bundleSpecialMinPrice || this.product.price_incl_tax
    },
    originalPrice () {
      return this.bundleMinPrice || this.product.original_price_incl_tax
    },
    isBundleOptionNotChangeable () {
      if (this.product.type_id !== 'bundle') return false
      return this.product.bundle_options.every(option => option && option.product_links.length <= 1)
    },
    bundleSpecialMinPrice () { // bundle special min price
      const bundleSpecialPrice = this.product.original_special_price / 100 || 0
      return bundleSpecialPrice && isSpecialPriceActive(this.product.special_from_date, this.product.special_to_date)
        ? getBundleOptionsMinPrices(this.product, this.product.price_type).map(optionPrice => round(optionPrice * bundleSpecialPrice, 2)).reduce((memo, current) => memo + current, 0)
        : 0
    },
    bundleMinPrice () { // bundle min price
      return getBundleOptionsMinPrices(this.product, this.product.price_type).reduce((memo, current) => memo + current, 0)
    },
    isBestseller () {
      return !!this.product.bestseller
    },
    isNew () {
      const currentDate = new Date()
      // eslint-disable-next-line no-trailing-spaces
      let expireDate = this.product.news_to_date 
        ? Date.parse(this.product.news_to_date)
        : 'none'
      return ((expireDate !== 'none') && (currentDate.getTime() < expireDate))
    },
    isOnSale () {
      return this.product.special_price && parseFloat(this.product.special_price) > 0 && this.isSaleDifference && this.omnibusPrice
    },
    ...mapState({
      isWishlistActive: state => state.ui.wishlist
    }),
    listByCode () {
      return this.$store.state.attribute.list_by_code
    },
    storeView () {
      return currentStoreView()
    }
  },
  mounted () {
    if (!this.linkActive) {
      this.$el.addEventListener(
        this.isTouch ? 'touchend' : 'mouseup',
        this.onTouchEnd
      )
      this.$el.addEventListener(
        this.isTouch ? 'touchstart' : 'mousedown',
        this.onTouchStart
      )
    }
  },
  destroy () {
    this.$el.removeEventListener(
      this.isTouch ? 'touchend' : 'mouseup',
      this.onTouchEnd,
      true
    )
    this.$el.removeEventListener(
      this.isTouch ? 'touchstart' : 'mousedown',
      this.onTouchStart,
      true
    )
  },
  beforeMount () {
    this.$bus.$on('product-after-priceupdate', this.onProductPriceUpdate)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-priceupdate', this.onProductPriceUpdate)
  }
}
</script>

<style lang="scss">
@media (min-width: 768px) {
  .product-link {
    .VueCarousel-navigation-button {
      display: none;
    }
    &:hover {
      .VueCarousel-navigation-button {
        display: block;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~theme/css/animations/transitions';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$bg-secondary: color(secondary, $colors-background);
$border-secondary: color(secondary, $colors-border);
$color-white: color(white);
$silver: color(silver);
$gray: color(gray);
$burnt-sienna: color(burnt-sienna);
$forest-green: color(forest-green);
$main: color(main);

.product {
  margin: 0 auto 10px;
  max-width: 297px;
  // height: 385px;
  // height: 455px;
  @media (max-width: 767px) {
    // height: 270px;
    max-width: 170px;
    min-width: 140px;
    margin: 0 auto  10px;
  }
  @media (max-width: 320px) {
    max-width: 145px;
  }
  &__icons {
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 1;
  }
}
.price-original {
  text-decoration: line-through;
  text-decoration-color: #CFCFCF;
  -webkit-text-decoration-color: #cfcfcf;
  @media (max-width: 767px) {
    white-space: nowrap;
    text-decoration-color: #CFCFCF;
    -webkit-text-decoration-color: #cfcfcf;
  }
  @media (max-width: 320px) {
    font-size: 12px;
    font-weight: 400;
  }
}

.product__icons {
  @media (max-width: 767px) {
    display: none;
  }
}

%label {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: $border-secondary;
  text-transform: uppercase;
  color: $color-white;
  font-size: 12px;
}

.product-image-box {
  background-color: #F2F7F9;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  max-width: calc( 100% - 30px);
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  overflow: hidden;
  // max-height: 200px;
  min-height: 240px;
  height: 100%;
  min-height: 155px;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60% auto;

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    padding-top: 40px;
    background: none;
  }

  @media (max-width: 767px) {
    padding-top: 5px;
    background: none;
    height: 140px;
    max-height: 140px;
    min-height: 140px;
    margin-top: 0px;
    // padding-top: 0px;
  }
  @media (min-width: 768px) {
    min-height: 190px;
  }
  @media (max-width: 350px) {
    padding-top: 0;
  }
  @media (min-width: 1200px) {
    min-height: 240px;
    // min-height: 200px;
  }

  &__content {
    display: none;
  }

  &--loaded {
    background-image: none;

    .product-image__content {
      display: block;
    }
  }

  &:hover {
    img {
      opacity: 1;
    }

    &.sale::after,
    &.new::after {
      opacity: 0.8;
    }
  }

  img {
    max-height: 200px;
    max-width: 100%;
    width: auto;
    height: auto;
    margin: auto;
    mix-blend-mode: darken;
    // opacity: 0.8;
    transform: scale(1);
    transition: 0.3s opacity $motion-main, 0.3s transform $motion-main;

    &[lazy="loaded"] {
      animation: products-loaded;
      animation-duration: 0.3s;
      -webkit-animation-duration: .7s;
    }

    @keyframes products-loaded {
      from {
        opacity: 0;
      }
      to {
        opacity: 0.8;
      }
    }
    @media (max-width: 767px) {
      height: 100%;
    }
    @media (max-width: 350px) {
      height: auto;
    }
  }

  &.sale {
    &::after {
      @extend %label;
      content: "Sale";
    }
  }

  &.new {
    &::after {
      @extend %label;
      content: "New";
    }
  }
}
.products-list {
  @media (max-width: 767px) {
    padding-top: 0;
  }
}
.product-long-name {
  font-size: 14px;
  @media (max-width: 767px) {
    font-size: 12px !important;
  }
}
.product-name {
  max-height: 48px;
  min-height: 48px;
  overflow: hidden;
  @media (max-width: 767px) {
    max-height: 58px;
    min-height: 58px;
    font-weight: 500;
    padding-top: 5px;
    margin-top: 0px;
    font-size: 14px;
  }
  @media (max-width: 350px) {
    margin-top: 0;
  }
}
.product-price {
  text-align: left;
  min-height: 24px;
  @media (max-width: 767px) {
    margin-bottom: 0px;
    margin-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    span:first-child {
      margin-right: 5px;
      font-size: 16px;
    }
  }
  @media (max-width: 350px) {
    line-height: 20px;
    span:first-child {
      margin-right: 5px;
      font-size: 16px;
    }
  }
}
  .product-link {
    background: #ffffff;
    // max-width: 335px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 768px) {
      &:hover {
        // left: 0;
        // position: absolute;
        // right: 0;
        // top: 0;
        z-index: 1;
        .product-attr {
          margin: 20px 0;
          padding: 0;
          display: block;
        }
      }
    }
    @media (max-width: 768px) {
      padding-top: 0;
    }
 }
.product-attr {
  display: none;
}
.badge {
  &.sale {
    background: $burnt-sienna;
  }
  &.bestseller {
    background: $forest-green;
  }
  &.new {
    background: $main;
  }
  color: white;
  position: absolute;
  top: 15px;
  left: 25px;
  z-index: 1;
  line-height: 1;
  padding: 4px 6px;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  font-weight: 500;
  font-size: 11px;
  @media (max-width: 767px) {
    left: 10px;
    top: 10px;
  }
}
.main-page {
  .product-name {
    @media (max-width: 767px) {
      margin-top: 5px;
    }
  }
  .product-price {
    @media (max-width: 767px) {
      padding-bottom: 5px;
    }
  }
  .badge-sale,
  .badge-new {
    @media (max-width: 767px) {
      bottom: -6px;
    }
  }
}
.main-page {
  &:hover .product-attr {
    display: none;
  }
  &:hover {
    @media (max-width: 767px) {
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }
  .product-name,
  .product-price {
    font-weight: 700;
    width: 85%;
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 767px) {
      margin-right: 15px;
    }
  }
  .add-to-cart {
    right: 0 !important;
  }
  background-color: white;
}

.update-add-to-cart {
  .add-to-cart {
  @media (max-width: 767px) {
    bottom: 0 !important;
    }
  }
}
.vertical-layout {
  margin-bottom: 20px;
  @media (max-width: 767px) {
    height: 195px;
    margin: 0;
  }
  height: 310px;
  max-width: none;
  .badge {
    @media (max-width: 767px) {
      top: 10px;
      right: auto;
      left: 10px;
      font-size: 12px;
    }
    top: 15px;
    right: 15px;
    left: auto;
    font-size: 18px;
  }
  .product__icons {
    right: 25px;
    top: auto;
    bottom: 20px;
    display: block;
    @media (max-width: 767px) {
      right: 10px;
      top: 10px;
    }
  }
  .add-to-cart {
    right: 110px;
    width: 200px;
    height: 44px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      padding-right: 10px;
      color: $color-white;
      content: 'Do koszyka';
    }
    @media (max-width: 767px) {
      right: 10px;
      width: 44px;
      height: 44px;
      &::before {
        padding: 0;
        content: '';
      }
    }
  }
  .product__icon {
    border: 2px solid $main;
    width: 44px;
    justify-content: center;
    align-items: inherit;
    display: flex;
    height: 44px;
    border-radius: 50px;
    i {
      font-size: 26px;
    }
    @media (max-width: 767px) {
      border: 1px solid $main;
      width: 28px;
      height: 28px;
      i {
        font-size: 18px;
      }
    }
  }
  .product__link {
    @media (max-width: 767px) {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    display: flex;
    padding-top: 40px;
    flex-direction: row;
    -ms-flex-direction: row;
    padding-bottom: 20px;
  }
  .product-link {
    padding: 0;
  }
  .vertical-layout__content {
    @media (max-width: 767px) {
      max-width: 60%;
      min-width: 60%;
      width: 60%;
    }
    display: flex;
    flex-direction: column;
    -ms-flex-direction: column;
    max-width: 70%;
    min-width: 70%;
    width: 70%;
  }
  .product-image-box {
    @media (max-width: 767px) {
      margin-left: 0;
      margin-right: 0;
    }
    margin-left: 20px;
    margin-right: 20px;
  }
  .product-name {
    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 16px;
    }
    max-height: none !important;
    border: 0;
    font-family: 'Krona One', sans-serif;
    font-weight: 600;
    font-size: 18px;
    margin-top: 0;
  }
  .product-price {
    margin-top: auto;
    padding-bottom: 0;
    .price-original {
      @media (max-width: 767px) {
        font-size: 12px;
      }
      font-size: 18px
    }
    font-size: 24px;
  }
}
.add-to-cart {
  position: absolute;
  right: 10px;
  padding: 0;
  font-size: 21px;
  border: 0;
  background-color: transparent;
  display: inline;
}
</style>
